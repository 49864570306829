<template>
  <input
    :id="props.id"
    ref="$el"
    v-model="model"
    :class="[{ error }, props.theme, props.size]"
    :disabled="props.disabled"
    :max="props.max"
    :maxlength="props.maxlength"
    :min="props.min"
    :minlength="props.minlength"
    :placeholder="props.placeholder"
    :required="props.required"
    tabindex="0"
    :type="type"
    @invalid="setErrorMessage"
    @keydown="onKeyDown"
  >
</template>

<script setup>
// Emitter
const emit = defineEmits([
  'input:change',
  'input:enter:pressed',
  'input:validate',
])

// Props
const props = defineProps({
  content: {
    default() {
      return ''
    },
    required: false,
    type: String,
  },
  disabled: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  errorMessage: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  id: {
    default() {
      return undefined
    },
    required: false,
    type: String,
  },
  max: {
    default() {
      return undefined
    },
    required: false,
    type: Number,
  },
  maxlength: {
    default() {
      return undefined
    },
    required: false,
    type: Number,
  },
  min: {
    default() {
      return undefined
    },
    required: false,
    type: Number,
  },
  minlength: {
    default() {
      return undefined
    },
    required: false,
    type: Number,
  },
  placeholder: {
    default() {
      return ''
    },
    required: false,
    type: String,
  },
  required: {
    default() {
      return false
    },
    required: false,
    type: Boolean,
  },
  size: {
    default() {
      return 'm'
    },
    required: false,
    type: String,
    validator(value) {
      return ['xs', 's', 'm', 'l', 'xl'].indexOf(value) !== -1
    },
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
  type: {
    default() {
      return 'text'
    },
    required: false,
    type: String,
    validator(value) {
      return ['email', 'number', 'password', 'submit', 'tel', 'text'].indexOf(value) !== -1
    },
  },
})

// Refs
const $el = ref(null)
const error = ref(false)
const model = ref('')

// Watchers
watch(() => model.value, () => {
  onChange()
})

// Methods
function getValue() {
  return model.value
}

function onChange() {
  $el.value?.setCustomValidity('')
  error.value = false
  emit('input:change', model.value)
}

function onKeyDown({ keyCode }) {
  if (keyCode === 13) {
    emit('input:enter:pressed', model.value)
  }
}

function reset() {
  model.value = props.content
}

function setErrorMessage() {
  if (!error.value.valueMessage) {
    return
  }
  $el.value?.setCustomValidity(error.value.valueMessage)
  validate()
}

function setValue(value) {
  model.value = value
}

function validate() {
  error.value = !$el.value?.checkValidity()
  emit('input:validate', error.value)
}

// Lifecycle Hooks
onBeforeMount(() => {
  model.value = props.content
})

onUpdated(() => {
  validate()
})

// Expose
defineExpose({
  $el,
  getValue,
  reset,
  setValue,
})
</script>

<style lang="postcss" scoped>
input {
  @apply w-full rounded transition duration-300 ease-out;

  &.dark {
    @apply bg-[#182427] border border-[#5D6669] text-white;

    &.error {
      @apply border-red-600;
    }

    &:focus {
      @apply border-[#CED0D0];
    }

    &:hover {
      @apply border-[#CED0D0];
    }

    &::placeholder {
      @apply text-white/50;
    }

    &[type="submit"] {
      @apply bg-winter-green-700 text-white;

      &:hover {
        @apply bg-winter-green-800;
      }
    }
  }

  &.light {
    @apply bg-white border border-grey-blue-300 text-winter-green-900;

    &.error {
      @apply border-red-600;
    }

    &:disabled {
      @apply bg-grey-blue-100 border-grey-blue-200;
    }

    &:focus {
      @apply border-livestorm-blue-700;
    }

    &:hover {
      @apply border-grey-blue-600;
    }

    &::placeholder {
      @apply text-grey-blue-500;
    }

    &[type="submit"] {
      @apply bg-livestorm-blue-700 text-white;

      &:hover {
        @apply bg-livestorm-blue-800;
      }
    }
  }

  &.xs {
    @apply h-6 px-2 text-xs;
  }

  &.s {
    @apply h-8 px-2 text-sm;
  }

  &.m {
    @apply h-10 px-3 text-sm;
  }

  &.l {
    @apply h-12 px-4 text-origin;
  }

  &.xl {
    @apply h-[72px] px-6 rounded-3xl;
  }

  &:focus {
    @apply outline-none;
  }

  &:hover {
    @apply outline-none;
  }

  &[type="submit"] {
    @apply border-0 cursor-pointer leading-[0];
  }
}
</style>
